











































import {Component, Vue} from 'vue-property-decorator';
import { pcaa } from 'area-data';
import CusTable from '@/components/table';


import Edit from "./edit.vue"
import JoinClass from "./join-class.vue"

@Component({
  components: {Edit, JoinClass},
})

export default class extends Vue {

  private nodes = [
    {label: '学生姓名', key: 'name', type: 'input'},
    {label: '家长电话', key: 'mobile', type: 'input'},
  ];
  private systemName = process.env.VUE_APP_SYSTEM_NAME;
  private editVisble: boolean = false;
  // private editClassVisble:boolean= false;
  private title: string = ''
  private editForm: any = {}

  $refs!: { table: CusTable };
  public provinceList;
  public cityList: any = [];
  public areaList: any = []
  private async update(type, data) {
    if (type === 'add') {
      this.title = '新增学员信息'
      this.editForm = null
    } else {
      this.title = '修改学员信息'
      const res: any = await this.axios.post(`/system/student/queryById/${data.id}`)
      if (res.result) {
        let {name, fatherPhone, motherPhone, sex, birthday, schoolId, id, provinceId, cityId, areaId, stageId, gradeId, userId} = res.json.student;
        this.cityList = res.json.student.cityId ? Object.entries(pcaa[res.json.student.provinceId]) : []
        this.areaList = res.json.student.areaId ? Object.entries(pcaa[res.json.student.cityId]) : []
        this.editForm = {
          name, fatherPhone, motherPhone, sex, birthday, schoolId, id, provinceId, cityId, areaId, stageId, gradeId, userId
        }
      }
    }
    this.editVisble = true
    this.provinceList = Object.entries(pcaa[86]);
  }

  private submitStudent(data, type) {
    this.editVisble = false;
    if (type === 'cancel') {
      return
    }
    //去提交数据
    this.updateForm(data)
  }

  private async updateForm(data) {
    const res: any = await this.axios.post(`/system/student/saveOrUpdate`, data);
    if (res.result) {
      this.$refs.table.request();
      this.$message.success('保存成功')
    } else {
      this.$message.error(res.msg)
    }
  }

  //删除
  private async remove(e) {
    let confirm = await this.$confirm('此操作将永久删除该校学生, 是否继续?', '提示', {type: 'warning'});
    if (confirm) {
      const res: any = await this.axios.post(`/system/student/deleteById?id=${e.id}`);
      res.result && this.$message.success('删除学生成功~！') && this.$refs.table.request();
    }
  }

  //启停用
  private async state(e) {
    let {id, status} = e
    const res: any = await this.axios.get(`/system/student/updateStatus?id=${id}&status=${status == 0 ? 1 : 0}`);
    res.result && this.$message.success('修改状态成功~！') && this.$refs.table.request();
  }

  //导入学员
  // private async uploadFile(ev){
  //     console.log(ev)
  //     let formData = new FormData()
  //     let file = ev.target.files[0]
  //     formData.append('file',file)
  //     console.log(formData)
  // }
}

<template>
  <el-dialog :title="title" :visible="visibel" width="40%" :show-close="false">
    <el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules">
      <el-form-item prop="name" label="学生姓名">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item prop="motherPhone" label="登录手机号">
        <el-input v-model="formData.motherPhone"></el-input>
      </el-form-item>
      <el-form-item prop="fatherPhone" label="联系人手机号">
        <el-input v-model="formData.fatherPhone"></el-input>
      </el-form-item>
      <!--            <el-form-item label="班级">-->
      <!--                <el-select v-model="formData.classIds" multiple collapse-tags>-->
      <!--                    <el-option v-for="item in classList" :label="item.title" :value="item.id" :key="item.id"></el-option>-->
      <!--                </el-select>-->
      <!--            </el-form-item>-->
      <el-form-item label="性别">
        <el-radio-group v-model="formData.sex">
          <el-radio :label="1" :value="1">男</el-radio>
          <el-radio :label="0" :value="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="formData.birthday"
            type="date"
            placeholder="选择日期"
            :picker-options="{disabledDate: (e) =>  new Date(e).getTime() > new Date().getTime()}">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="学段" prop="stageId">
        <el-select v-model="formData.stageId" placeholder="请选择学段" clearable>
          <el-option v-for="o in stageList" :key="o.name" :label="o.name" :value="o.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="年级" prop="gradeId">
        <el-select v-model="formData.gradeId" placeholder="请选择年级" clearable @change="gradeChange">
          <el-option v-for="o in gradeList" :key="o.code" :label="o.name" :value="o.code"/>
        </el-select>
      </el-form-item>
      <el-form-item label="省份" prop="provinceId">
        <el-select v-model="formData.provinceId" placeholder="请选择省份" clearable @change="provinceChange">
          <el-option v-for="(o, idx) in provinceList" :key="idx" :label="o[1]" :value="o[0]"/>
        </el-select>
      </el-form-item>
      <el-form-item label="城市" prop="cityId">
        <el-select v-model="formData.cityId" placeholder="请选择城市" clearable @change="cityChange">
          <el-option v-for="o in cityList" :key="idx" :label="o[1]" :value="o[0]"/>
        </el-select>
      </el-form-item>
      <el-form-item label="区/县" prop="areaId">
        <el-select v-model="formData.areaId" placeholder="请选择区/县" clearable>
          <el-option v-for="(o, idx) in areaList" :key="idx" :label="o[1]" :value="o[0]"/>
        </el-select>
      </el-form-item>
      <el-form-item label="所在学校" prop="schoolId">
        <el-select placeholder="选择学校" filterable size="medium" v-model="formData.schoolId">
          <el-option v-for="(option, idx) in schoolList" :key="idx" :value="option.id" :label="option.name" />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handler">确 定</el-button>
        </span>
  </el-dialog>
</template>
<script>
import { pcaa } from 'area-data';

export default {
  name: 'app',
  components: {},
  props: {
    editVisble: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    title: {
      type: String
    },
    cityList: {
      type: Array,
      default: () => ([])
    },
    areaList: {
      type: Array,
      default: () => ([])
    },
    provinceList: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    editVisble: {
      handler(val) {
        this.visibel = val
      },
      immediate: true
    },
    data: {
      handler(val) {
        if (val === null) return
        this.formData = JSON.parse(JSON.stringify(val))
      },
      immediate: true
    },
    'formData.areaId':{
      handler: function (newVal) {
        if (newVal != null) {
          this.axios.post('/admin/publicSchool/queryAll', { areaId: newVal }).then(res => {
            this.schoolList = res.json;
          })
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      visibel: false,
      classList: [],
      formData: {
        name: "", // 学生名称
        fatherPhone: "", // 父亲电话
        motherPhone: "", // 母亲电话
        classIds: [], // 班级id（数组）
        sex: "", //性别  0女 1男
        birthday: "", // 出生日期
        schoolName: "", // 所在学校,
        provinceId: null,
        cityId: null,
        areaId: null,
        loginCount: 0,
        stageId: '',
        schoolId: null,
        gradeId: '',
        gradeName: ''
      },
      schoolList: [],
      gradeList: [],
      stageList: [{ name: '小学', id: 'primary' }, { name: '初中', id: 'middle' }, { name: '高中', id: 'high' }],
      rules: {
        name: [{required: true, message: '请填写学生姓名!', trigger: 'blur'}],
        fatherPhone: [
          // {required: true, message: '此项为必填项!', trigger: 'blur'},
          {pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/, message: '请输入正确的手机号', trigger: 'change'}
        ],
        motherPhone: [
          {required: true, message: '此项为必填项!', trigger: 'blur'},
          {pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        gradeId: [{required: true, message: '请选择年级!', trigger: 'blur'}],
        stageId: [{required: true, message: '请选择学段!', trigger: 'blur'}],
        provinceId: [{required: true, message: '请选择省份!', trigger: 'blur'}],
        cityId: [{required: true, message: '请选择城市!', trigger: 'blur'}],
        areaId: [{required: true, message: '请选择区县!', trigger: 'blur'}],
        schoolId: [{required: true, message: '请选择学校!', trigger: 'blur'}]
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("submit", null, 'cancel')
    },
    handler(type) {
      let data = this.formData

      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$emit("submit", data, type)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async getClass() {
      const res = await this.axios.post(`/class/queryAll`, {});
      this.classList = res.json
    },
    provinceChange(e) {
      this.formData.cityId = null;
      this.formData.areaId = null;
      this.$parent.cityList = e ? Object.entries(pcaa[e]) : [];
    },
    cityChange(e) {
      this.formData.areaId = null;
      this.$parent.areaList = e ? Object.entries(pcaa[e]) : [];
    },
    gradeChange(e) {
      this.formData.gradeName = this.gradeList.find(i => i.code == e).name;
    }
  },
  created() {
    this.getClass();
    this.axios.post('/system/dictionary/queryPage', {typeCode: 'GRADE'}).then(res => {
      this.gradeList = res.records
    })
  }
}
</script>
<style lang="less" scoped>
.el-dialog__body {
  overflow: auto;
  max-height: 200px !important;

  .el-select, .el-select--default, .el-date-editor {
    width: 100%;
  }
}
</style>
